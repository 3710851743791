import axios from 'axios';
import { getAuthToken } from 'src/common/utils/getAuthToken';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 15000,
});

instance.interceptors.request.use(async (config) => {
  const token = await getAuthToken();

  config.headers = config.headers ?? {};

  if (token) {
    config.headers.Authorization = config.headers.Authorization ?? token;
  }

  return config;
});

export default instance;
