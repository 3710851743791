import type { TypographyVariantsOptions } from '@mui/material/styles';
import { NextFontWithVariable } from 'next/dist/compiled/@next/font';
import { Instrument_Serif, Inter, Roboto_Mono } from 'next/font/google';
import localFont from 'next/font/local';

export const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap',
});

export const instrument_serif = Instrument_Serif({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-instrument-serif',
  weight: '400',
});

export const roboto_mono = Roboto_Mono({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-roboto-mono',
});

export const aeonikPro = localFont({
  src: [
    {
      path: '../../../../public/assets/fonts/AeonikPro-Air.otf',
      weight: '100',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/AeonikPro-Thin.otf',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/AeonikPro-Light.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/AeonikPro-Regular.otf',
      weight: '400',
      style: 'normal',
    },

    {
      path: '../../../../public/assets/fonts/AeonikPro-Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/AeonikPro-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/AeonikPro-Black.otf',
      weight: '900',
      style: 'normal',
    },
  ],
  variable: '--font-aeonik-pro',
});

export const okta_neue = localFont({
  src: [
    {
      path: '../../../../public/assets/fonts/OktaNeue-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/OktaNeue-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/OktaNeue-SemiBold.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../../../public/assets/fonts/OktaNeue-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-okta-neue',
});

export const thicccboi = localFont({
  src: [
    {
      path: '../../../../public/assets/fonts/THICCCBOI-Bold.ttf',
      weight: '800',
      style: 'normal',
    },
  ],
  variable: '--font-thicccboi',
});

interface ThemeFont {
  label: string;
  nextFont: NextFontWithVariable;
  cssVariable: string;
}

export const themeFonts: ThemeFont[] = [
  {
    label: 'Inter',
    nextFont: inter,
    cssVariable: '--font-inter',
  },
  {
    label: 'Instrument Serif',
    nextFont: instrument_serif,
    cssVariable: '--font-instrument-serif',
  },
  {
    label: 'Roboto Mono',
    nextFont: roboto_mono,
    cssVariable: '--font-roboto-mono',
  },
  {
    label: 'Okta Neue',
    nextFont: okta_neue,
    cssVariable: '--font-okta-neue',
  },
  {
    label: 'Thicccboi',
    nextFont: thicccboi,
    cssVariable: '--font-thicccboi',
  },
  {
    label: 'Aeonik Pro',
    nextFont: aeonikPro,
    cssVariable: '--font-aeonik-pro',
  },
];

export const buildTypographyVariables = () => {
  return themeFonts.map(({ nextFont }) => nextFont.variable).join(' ');
};

export const createTypography = (): TypographyVariantsOptions => {
  return {
    fontFamily: inter.style.fontFamily,
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },

    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.66,
    },
    subtitle1: {
      fontFamily: okta_neue.style.fontFamily,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontFamily: okta_neue.style.fontFamily,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '3.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '3rem',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '2.25rem',
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: thicccboi.style.fontFamily,
      fontWeight: 800,
      fontSize: '1.125rem',
      lineHeight: 1.2,
    },
  };
};
