import { getAuthToken as getDynamicAuthToken } from '@dynamic-labs/sdk-react-core';

export const getAuthToken = async (): Promise<string | null> => {
  const authToken = await getDynamicAuthToken();

  if (authToken) {
    return `Bearer ${authToken}`;
  }

  return null;
};
