import { datadogRum } from '@datadog/browser-rum';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRouter, useSearchParams } from 'next/navigation';
import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { paths } from 'src/config/paths';
import { ProjectDTO } from 'src/modules/projects/infra/dtos';
import { projectService } from 'src/modules/projects/infra/projectService';
import { saveProjectInviteCode } from 'src/modules/projects/utils';

import { LoadingBackdrop } from '@/components/LoadingBackdrop';

import { User } from '../domain';
import { authService } from '../infra/authService';
import { CreateUserDTO, UserDTO } from '../infra/dtos';

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  loadUserData: () => Promise<ProjectDTO[] | undefined>;
  signIn: () => Promise<void>;
  user: User | undefined;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  loadUserData: () => Promise.resolve(undefined),
  signIn: () => Promise.resolve(),
  user: undefined,
};

export interface AuthContextType extends State {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<User | undefined>();

  const { handleLogOut, user: dynamicUser, authToken } = useDynamicContext();

  const address = dynamicUser?.verifiedCredentials.find((credential) => credential.format === 'blockchain')?.address;
  const email = dynamicUser?.email;

  const { mutateAsync: loginUser } = useMutation({
    mutationFn: (body: CreateUserDTO) => authService.login(body),
  });

  const { refetch: fetchProjects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => projectService.getAll(),
    enabled: false,
  });

  const initialize = useCallback(async (): Promise<void> => {
    try {
      if (authToken) {
        await loadUserData();
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setUser(undefined);
      }
    } catch (err) {
      datadogRum.addError(`Error initializing authentication: ${err.message}`);
    } finally {
      setIsInitialized(true);
    }
  }, []);

  const loadUserData = async (): Promise<ProjectDTO[] | undefined> => {
    const userResponse: UserDTO = await loginUser({
      address,
      email,
    });

    const { data: projectMembershipsResponse } = await fetchProjects();

    setUser((prevState) => ({
      ...prevState,
      acceptedTerms: userResponse.acceptedTerms,
      address: userResponse.address,
      id: userResponse.id,
      email: userResponse.email,
      projectMemberships:
        projectMembershipsResponse?.map((projectMembership) => ({
          id: projectMembership.id,
          slug: projectMembership.slug,
          name: projectMembership.name,
        })) || [],
    }));

    return projectMembershipsResponse;
  };

  const signIn = useCallback(async (): Promise<void> => {
    if (!dynamicUser && !authToken) {
      return;
    }

    setIsAuthenticated(true);

    try {
      setIsAuthenticating(true);

      const projectMembershipsResponse = await loadUserData();

      const hasProjects = projectMembershipsResponse && projectMembershipsResponse?.length > 0;

      if (hasProjects) {
        const projectSlug = projectMembershipsResponse[0].slug;

        return router.push(paths.project.incentives.home(projectSlug));
      }

      router.push(paths.onboarding.project);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsAuthenticating(false);
    }
  }, [dynamicUser, authToken]);

  const signOut = async () => await handleLogOut();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    saveProjectInviteCode(searchParams);
  }, [searchParams]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitialized,
        user,
        signIn,
        signOut,
        loadUserData,
      }}
    >
      <>
        {children}
        <LoadingBackdrop open={isAuthenticating} text="Logging in" />
      </>
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthConsumer = AuthContext.Consumer;
