import { ReadonlyURLSearchParams } from 'next/navigation';

import { formatSecondsToNumberOfDays } from '@/utils/dates';

const FUUL_PROJECT_INVITE_CODE_KEY = 'fuul.inviteCode';

export const removeBudgetCooldownPeriod = formatSecondsToNumberOfDays(
  Number(process.env.NEXT_PUBLIC_TEMPORAL_REMOVE_BUDGET_COOLDOWN_PERIOD_IN_SECONDS),
);

export const removeBudgetWindowPeriod = formatSecondsToNumberOfDays(
  Number(process.env.NEXT_PUBLIC_TEMPORAL_REMOVE_BUDGET_WINDOW_PERIOD_IN_SECONDS),
);

export const saveProjectInviteCode = (params: ReadonlyURLSearchParams | null) => {
  if (!params?.has('referrer')) return;

  localStorage.setItem(FUUL_PROJECT_INVITE_CODE_KEY, params.get('referrer') as string);
};

export const getProjectInviteCode = () => {
  return localStorage.getItem(FUUL_PROJECT_INVITE_CODE_KEY);
};
