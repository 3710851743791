import { datadogRum } from '@datadog/browser-rum';
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum';
import { MagicWalletConnectors } from '@dynamic-labs/magic';
import { DynamicContextProps } from '@dynamic-labs/sdk-react-core';
import { ProviderEnum } from '@dynamic-labs/types';
import Cookies from 'js-cookie';
import { FUUL_USER_AUTH_TOKEN_KEY } from 'src/modules/auth/constants';
import { authService } from 'src/modules/auth/infra/authService';
import { onLogout, saveSignedMessage } from 'src/modules/auth/utils';

export const commonDynamicContextProps: DynamicContextProps['settings'] = {
  appName: 'Fuul',
  environmentId: process.env.NEXT_PUBLIC_DYNAMIC_ENVIRONMENT_ID as string,
  // @ts-ignore
  walletConnectors: [EthereumWalletConnectors, MagicWalletConnectors],
  shadowDOMEnabled: false,
  eventsCallbacks: {
    onSignedMessage: (params) => {
      saveSignedMessage(params.messageToSign, params.signedMessage);
    },
    onLogout,
    onAuthSuccess: ({ authToken }) => {
      Cookies.set(FUUL_USER_AUTH_TOKEN_KEY, authToken as string, {
        expires: 1,
      });
    },
    onUserProfileUpdate: async (args) => {
      const twitterHandle = args.verifiedCredentials.find(
        (credential) => credential.oauthProvider === ProviderEnum.Twitter,
      )?.oauthUsername;

      const githubUsername = args.verifiedCredentials.find(
        (credential) => credential.oauthProvider === ProviderEnum.Github,
      )?.oauthUsername;

      const discordUsername = args.verifiedCredentials.find(
        (credential) => credential.oauthProvider === ProviderEnum.Discord,
      )?.oauthUsername;

      try {
        await authService.updateUser({
          twitter_handle: twitterHandle,
          github_username: githubUsername,
          discord_username: discordUsername,
        });
      } catch (error) {
        datadogRum.addError(`Error updating user profile: ${error.message}`);
      }
    },
  },
};
