export const paths = {
  index: '/',
  requestAccess: '/auth/request-access',

  onboarding: {
    project: '/onboarding/project',
  },

  landing: (slug: string) => `/landing/${slug}`,
  claim: '/claim',

  referrals: (slug: string) => `/referrals/${slug}`,
  incentives: (slug: string) => `/incentives/${slug}`,
  points: (slug: string) => `/points/${slug}`,

  project: {
    create: '/projects/create',
    settings: (slug: string, tab?: string) => `/projects/${slug}/settings${tab ? `?tab=${tab}` : ''}`,
    handyLinks: (slug: string) => `/projects/${slug}/handy-links`,
    gettingStarted: (slug: string) => `/projects/${slug}/getting-started`,
    customize: (slug: string) => `/projects/${slug}/customize`,
    customizePage: (slug: string, pageId: string) => `/projects/${slug}/customize/${pageId}`,
    conversions: {
      home: (slug: string, type?: string) => `/projects/${slug}/conversions${type ? `/${type}` : ''}`,
      create: (slug: string) => `/projects/${slug}/conversions/create`,
      setup: (slug: string) => `/projects/${slug}/conversions/setup`,
    },
    triggers: {
      home: (slug: string) => `/projects/${slug}/triggers`,
    },
    incentives: {
      home: (slug: string) => `/projects/${slug}/incentives`,
    },
    points: {
      overview: (slug: string) => `/projects/${slug}/points/overview`,
      // TODO:
      // Esto hay que eliminarlo para integrarlo en un solo form de incentives
      home: (slug: string) => `/projects/${slug}/points`,
      create: (slug: string) => `/projects/${slug}/points/create`,
      edit: (projectSlug: string, conversionSlug: string, payoutTermId: string) =>
        `/projects/${projectSlug}/points/${conversionSlug}/${payoutTermId}`,
    },
    activity: {
      home: (slug: string) => `/projects/${slug}/activity`,
      conversions: (slug: string) => `/projects/${slug}/activity/conversions`,
      events: (slug: string) => `/projects/${slug}/activity/events`,
    },
    fraud: {
      home: (slug: string) => `/projects/${slug}/fraud-detection`,
    },
    affiliate: {
      overview: (slug: string) => `/projects/${slug}/affiliate/overview`,
      budgets: (slug: string) => `/projects/${slug}/affiliate/budgets`,
      audiences: (slug: string) => `/projects/${slug}/affiliate/audiences`,
      rules: (slug: string) => `/projects/${slug}/affiliate/rules`,
      editPayoutRule: (projectSlug: string, conversionSlug: string, payoutTermId: string) =>
        `/projects/${projectSlug}/affiliate/rules/${conversionSlug}/${payoutTermId}`,
    },
    participants: {
      home: (slug: string) => `/projects/${slug}/participants`,
      affiliates: (slug: string) => `/projects/${slug}/participants/affiliates`,
      users: (slug: string) => `/projects/${slug}/participants/users`,
    },
    attribution: {
      overview: (slug: string) => `/projects/${slug}/attribution/overview`,
      urlBuilder: (slug: string) => `/projects/${slug}/attribution/url-builder`,
    },
    frames: {
      home: (slug: string) => `/projects/${slug}/frames`,
      edit: (slug: string, id: string) => `/projects/${slug}/frames/${id}`,
    },
    setup: (slug: string) => `/projects/${slug}/setup`,
  },

  affiliates: {
    findProjects: '/affiliates/find-projects',
    projectDetails: (slug: string) => `/affiliates/find-projects/${slug}`,
    claim: '/affiliates/claim',
    analytics: '/affiliates/analytics',
  },

  401: '/401',
  404: '/404',
  500: '/500',
};
