import axios from 'axios';
import { mapToData } from 'src/common/http/mapToData';
import { mapToErrors } from 'src/common/http/mapToErrors';

import axiosInstance from '../../../common/http/axiosInstance';
import {
  CreatedPipedriveLeadDTO,
  CreatedPipedriveLeadNoteDTO,
  CreatePipedriveLeadDTO,
  CreatePipedriveLeadNoteDTO,
  CreateUserDTO,
  UpdateUserDTO,
  UserDTO,
} from './dtos';

const requests = {
  post: async <T, K>(url: string, body: T): Promise<K> => axios.post(url, body).then(mapToData).catch(mapToErrors),
};

const userRequests = {
  patch: async <T, K>(url: string, body: T): Promise<K> =>
    axiosInstance.patch(url, body).then(mapToData).catch(mapToErrors),
  post: async <T, K>(url: string, body: T): Promise<K> =>
    axiosInstance.post(url, body).then(mapToData).catch(mapToErrors),
};

export const authService = {
  createLead: async (body: CreatePipedriveLeadDTO): Promise<CreatedPipedriveLeadDTO> =>
    requests.post<CreatePipedriveLeadDTO, CreatedPipedriveLeadDTO>('/api/pipedrive/lead', body),
  createLeadNote: async (body: CreatePipedriveLeadNoteDTO): Promise<CreatedPipedriveLeadNoteDTO> =>
    requests.post<CreatePipedriveLeadNoteDTO, CreatedPipedriveLeadNoteDTO>('/api/pipedrive/lead-note', body),
  updateUser: async (body: UpdateUserDTO): Promise<void> => userRequests.patch<UpdateUserDTO, void>('users', body),
  login: async (body: CreateUserDTO): Promise<UserDTO> => userRequests.post<CreateUserDTO, UserDTO>('users', body),
};
