import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  text?: string;
}

export const LoadingBackdrop = ({ open, text }: Props): JSX.Element => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 600,
      }}
      open={open}
      onClick={() => {}}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap="1rem">
        <CircularProgress color="inherit" />
        {text && (
          <Typography variant="subtitle1" color="#fff">
            {text}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};
