import { AxiosError } from 'axios';
import { isArray } from 'lodash';

export interface BackendError {
  error: string;
  message: string | string[];
  statusCode: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mapToErrors = (error: AxiosError<BackendError> | unknown) => {
  if (error instanceof AxiosError) {
    if (error?.response?.data && error.response.data.message) {
      if (isArray(error.response.data.message)) {
        error.response.data.message.map((msg: string) => {
          throw new Error(msg);
        });
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
};
