export default function AppVersion() {
  if (!process.env.NEXT_PUBLIC_APP_VERSION) return null;

  return (
    <div
      data-app-version={process.env.NEXT_PUBLIC_APP_VERSION}
      style={{
        display: 'none',
      }}
    ></div>
  );
}
