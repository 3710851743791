import { datadogRum } from '@datadog/browser-rum';
import { AxiosRequestConfig } from 'axios';
import request from 'graphql-request';
import { mapToErrors } from 'src/common/http/mapToErrors';

import instance from '../../../common/http/axiosInstance';
import { mapToData } from '../../../common/http/mapToData';
import { graphql } from '../../../gql';
import {
  AddedOrRemovedProjectMembershipDTO,
  AddOrRemoveProjectMembershipDTO,
  AudienceEntriesResponse,
  AudienceEntry,
  AudienceResponseDTO,
  CreateAudienceDto,
  CreateAudienceResponseDTO,
  CreatedProjectDTO,
  CreateProjectApiKeyDTO,
  CustomizablePageDto,
  DeleteAudienceResponseDto,
  FuulFeeAmountDTO,
  InitializeProjectDTO,
  ProjectCustomizationResponseDto,
  ProjectDTO,
  ProjectIntegrationHealthDTO,
  ProjectOnboardingStateDTO,
  ToggleProjectMembershipDTO,
  UpdateProjectCustomizablePageDto,
  UpdateProjectDTO,
  UpdateProjectPageSchemaDto,
  UpdateProjectThemeDto,
  UploadProjectMetadataDTO,
  UpsertAudienceEntryDTO,
} from './dtos';

const projectBudgetsQueryDocument = graphql(/* GraphQL */ `
  query projectBudgetsQueryDocument($owner: String!) {
    budgets(where: { owner: $owner }, subgraphError: allow) {
      amount
      remainingBudgetReferenceAmount
      currency
      owner {
        lastRemovalApplication
      }
    }
  }
`);

const projectsBudgetsQueryDocument = graphql(/* GraphQL */ `
  query projectsBudgetsQueryDocument($owners: [String!]!) {
    budgets(where: { owner_in: $owners }, subgraphError: allow) {
      amount
      remainingBudgetReferenceAmount
      currency
      chainId
      owner {
        id
      }
    }
  }
`);

const projectOnchainMembersQueryDocument = graphql(/* GraphQL */ `
  query projectOnchainMembersQueryDocument($deployedAddress: Bytes!) {
    projectMembers(where: { project_: { deployedAddress: $deployedAddress } }, subgraphError: allow) {
      address
      role
    }
  }
`);

const requests = {
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    instance.get(url, config).then(mapToData).catch(mapToErrors),
  postFormData: async <T, K>(url: string, body?: T): Promise<K> =>
    instance
      .post(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(mapToData)
      .catch(mapToErrors),
  post: async <T, K>(url: string, body?: T): Promise<K> => instance.post(url, body).then(mapToData).catch(mapToErrors),
  patch: async <T, K>(url: string, body?: T): Promise<K> =>
    instance.patch(url, body).then(mapToData).catch(mapToErrors),
  patchFormData: async <T, K>(url: string, body?: T): Promise<K> =>
    instance
      .patch(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(mapToData)
      .catch(mapToErrors),
  delete: async <T>(url: string): Promise<T> => instance.delete(url).then(mapToData).catch(mapToErrors),
};

export const projectService = {
  getAll: async (): Promise<ProjectDTO[]> => requests.get<ProjectDTO[]>('projects'),
  getById: async (id: string): Promise<ProjectDTO> => requests.get<ProjectDTO>(`projects/${id}`),
  getOnboardingState: async (id: string): Promise<ProjectOnboardingStateDTO> =>
    requests.get<ProjectOnboardingStateDTO>(`projects/${id}/onboarding`),
  getIntegrationHealth: async (id: string): Promise<ProjectIntegrationHealthDTO> =>
    requests.get<ProjectIntegrationHealthDTO>(`projects/${id}/integration_health`),

  create: async (body: FormData): Promise<CreatedProjectDTO> =>
    requests.postFormData<FormData, CreatedProjectDTO>('projects', body),
  createApiKey: async (id: string, body: CreateProjectApiKeyDTO): Promise<string> =>
    requests.post<CreateProjectApiKeyDTO, string>(`projects/${id}/api_keys`, body),
  deleteApiKey: async (projectId: string, apiKeyId: string): Promise<string> =>
    requests.delete(`projects/${projectId}/api_keys/${apiKeyId}`),
  addProjectAdmin: async (
    id: string,
    body: AddOrRemoveProjectMembershipDTO,
  ): Promise<AddedOrRemovedProjectMembershipDTO> =>
    requests.post<AddOrRemoveProjectMembershipDTO, AddedOrRemovedProjectMembershipDTO>(`projects/${id}/members`, body),
  uploadMetadata: async (id: string): Promise<UploadProjectMetadataDTO> => requests.post(`projects/${id}/metadata`),
  publishMetadata: async (id: string): Promise<void> => requests.patch(`projects/${id}/metadata/publish`),

  update: async (id: string, body: UpdateProjectDTO | FormData): Promise<ProjectDTO> =>
    requests.patch<UpdateProjectDTO | FormData, ProjectDTO>(`projects/${id}`, body),
  toggleProjectMember: async (
    id: string,
    address: string,
    body: ToggleProjectMembershipDTO,
  ): Promise<AddedOrRemovedProjectMembershipDTO> =>
    requests.patch<ToggleProjectMembershipDTO, AddedOrRemovedProjectMembershipDTO>(
      `projects/${id}/members/${address}`,
      body,
    ),
  initialize: async (id: string, body: InitializeProjectDTO): Promise<ProjectDTO> =>
    requests.patch(`projects/${id}/initialize`, body),

  removeProjectAdmin: async (id: string, address: string): Promise<AddedOrRemovedProjectMembershipDTO> =>
    requests.delete<AddedOrRemovedProjectMembershipDTO>(`projects/${id}/members/${address}`),

  getProjectBudgets: async (owner: string, url: string) =>
    request(url, projectBudgetsQueryDocument, {
      owner,
    })
      .then(({ budgets }) => budgets)
      .catch((error) => {
        datadogRum.addError(`Error fetching project budgets: ${error}`);
        return [];
      }),
  getProjectsBudgets: async (owners: string[], url: string) =>
    request(url, projectsBudgetsQueryDocument, {
      owners,
    })
      .then(({ budgets }) => budgets)
      .catch((error) => {
        datadogRum.addError(`Error fetching project budgets: ${error}`);
        return [];
      }),
  getProjectOnchainMembers: async (deployedAddress: string, url: string) =>
    request(url, projectOnchainMembersQueryDocument, {
      deployedAddress,
    })
      .then(({ projectMembers }) => projectMembers)
      .catch((error) => {
        datadogRum.addError(`Error fetching onchain project members: ${error}`);
        return [];
      }),

  audiences: {
    getAll: async (projectId: string): Promise<AudienceResponseDTO[]> =>
      requests.get<AudienceResponseDTO[]>(`projects/${projectId}/audience-segments`),
    get: async (projectId: string, audienceId: string): Promise<AudienceResponseDTO> =>
      requests.get<AudienceResponseDTO>(`projects/${projectId}/audience-segments/${audienceId}`),
    getEntries: async (
      projectId: string,
      audienceId: string,
      params: Record<string, any>,
    ): Promise<AudienceEntriesResponse> =>
      requests.get<AudienceEntriesResponse>(`projects/${projectId}/audience-segments/${audienceId}/entries`, {
        params,
      }),
    upsertEntry: async (projectId: string, audienceId: string, body: UpsertAudienceEntryDTO): Promise<unknown> =>
      requests.post<unknown, AudienceEntry>(`projects/${projectId}/audience-segments/${audienceId}/entries`, body),
    removeEntry: async (projectId: string, audienceId: string, address: string): Promise<unknown> =>
      requests.delete<unknown>(`projects/${projectId}/audience-segments/${audienceId}/entries/${address}`),
    create: async (projectId: string, body: CreateAudienceDto): Promise<CreateAudienceResponseDTO> =>
      requests.post<CreateAudienceDto, CreateAudienceResponseDTO>(`projects/${projectId}/audience-segments`, body),
    edit: async (projectId: string, audienceId: string, body: CreateAudienceDto): Promise<CreateAudienceResponseDTO> =>
      requests.patch<CreateAudienceDto, CreateAudienceResponseDTO>(
        `projects/${projectId}/audience-segments/${audienceId}`,
        body,
      ),
    delete: async (projectId: string, audienceId: string): Promise<DeleteAudienceResponseDto> =>
      requests.delete<DeleteAudienceResponseDto>(`projects/${projectId}/audience-segments/${audienceId}`),
  },

  updatePageSchema: async (
    projectId: string,
    pageId: string,
    body: UpdateProjectPageSchemaDto,
  ): Promise<ProjectCustomizationResponseDto> =>
    requests.patch(`projects/${projectId}/customizations/pages/${pageId}`, body),

  updateProjectTheme: async (
    projectId: string,
    body: UpdateProjectThemeDto,
  ): Promise<ProjectCustomizationResponseDto> => requests.patch(`projects/${projectId}/customizations/theme`, body),

  updateProjectPageMetadata: async (
    projectId: string,
    pageId: string,
    body: FormData,
  ): Promise<ProjectCustomizationResponseDto> =>
    requests.patchFormData(`projects/${projectId}/customizations/pages/${pageId}/metadata`, body),

  updateCustomizablePage: async (
    projectId: string,
    pageId: string,
    body: UpdateProjectCustomizablePageDto,
  ): Promise<CustomizablePageDto> => requests.patch(`projects/${projectId}/customizations/pages/${pageId}`, body),

  twitter: {
    verifyFollow: async (projectId: string, username: string): Promise<{ follows: boolean }> =>
      requests.get<{ follows: boolean }>(`projects/${projectId}/twitter/follows?username=${username}`),
  },

  fuul_fee: {
    getFuulFeeAmount: async (projectId: string, amount: string, currencyName: string): Promise<FuulFeeAmountDTO> =>
      requests.get<FuulFeeAmountDTO>(`projects/${projectId}/fuul-fee?currencyName=${currencyName}&amount=${amount}`),
  },
};
