import { createTheme } from '@fuul/web-ui-components';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'src/common/components/Toaster';
import { SettingsConsumer, SettingsProvider } from 'src/common/context/settings/SettingsContext';

import { createTypography } from './create-typography';

export const FuulThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <SettingsProvider>
      <SettingsConsumer>
        {(settings) => {
          const theme = createTheme({
            colorPreset: 'blue',
            contrast: settings.contrast,
            direction: settings.direction,
            paletteMode: settings.paletteMode,
            responsiveFontSizes: true,
            typography: createTypography(),
          });

          return (
            <ThemeProvider theme={theme}>
              {children}
              <Toaster />
            </ThemeProvider>
          );
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
};
