import '../styles/globals.css';

import { datadogRum } from '@datadog/browser-rum';
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import nProgress from 'nprogress';
import { ReactElement, ReactNode, Suspense, useEffect } from 'react';
import { FuulThemeProvider } from 'src/common/context/theme/FuulThemeProvider';
import { useNProgress } from 'src/common/hooks/useNprogress';
import { createEmotionCache } from 'src/common/utils/create-emotion-cache';
import { AuthConsumer, AuthProvider } from 'src/modules/auth/context/AuthContext';

import AppVersion from '@/components/AppVersion';
import { SplashScreen } from '@/components/SplashScreen';
import { commonDynamicContextProps } from '@/context/dynamic-context';
import { SettingsConsumer } from '@/context/settings/SettingsContext';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

nProgress.configure({
  showSpinner: false,
});

const clientSideEmotionCache = createEmotionCache();

dayjs.extend(relativeTime);
dayjs.extend(utc);

function MyApp(props: AppProps): JSX.Element {
  // @ts-ignore
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  // @ts-ignore
  const getLayout = Component.getLayout ?? ((page: any) => page);

  useNProgress();

  useEffect(() => {
    const env = process.env.NEXT_PUBLIC_APP_ENV || 'development';

    if (env !== 'development') {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
        site: 'us5.datadoghq.com',
        service: 'fuul-webapp',
        env,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
        sessionSampleRate: 50,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <FuulThemeProvider>
        <SettingsConsumer>
          {(settings) => {
            return (
              <DynamicContextProvider theme={settings.paletteMode} settings={commonDynamicContextProps}>
                <DynamicWagmiConnector>
                  <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Suspense>
                        <AuthProvider>
                          <AuthConsumer>
                            {(auth) => {
                              // Prevent guards from redirecting
                              const showSlashScreen = !auth.isInitialized;

                              return (
                                <>
                                  {showSlashScreen ? (
                                    <SplashScreen />
                                  ) : (
                                    <>
                                      <CssBaseline />
                                      {getLayout(<Component {...pageProps} />)}
                                    </>
                                  )}
                                  <AppVersion />
                                </>
                              );
                            }}
                          </AuthConsumer>
                        </AuthProvider>
                      </Suspense>
                    </LocalizationProvider>
                  </QueryClientProvider>
                </DynamicWagmiConnector>
              </DynamicContextProvider>
            );
          }}
        </SettingsConsumer>
      </FuulThemeProvider>
    </CacheProvider>
  );
}
export default MyApp;
