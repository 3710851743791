import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type DayMomentType = 'start' | 'end';

export const formateDateToIso = (date: Date, dayMoment: DayMomentType) => {
  if (dayMoment === 'start') return dayjs.utc(date).startOf('day').toISOString();

  return dayjs.utc(date).endOf('day').toISOString();
};

export const buildFromToQueryParams = (from: Date, to: Date) => {
  return `?from=${formateDateToIso(from, 'start')}&to=${formateDateToIso(to, 'end')}`;
};

export const formatSecondsToDurationInDays = (seconds: number) => {
  const days = seconds / (60 * 60 * 24);
  return `${days} days`;
};

export const defaultDateTimeFormat = 'MMM D, YYYY HH:mm:ss';

export const today = dayjs().format('YYYY-MM-DD');
export const sevenDaysAgo = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
export const now = dayjs().format(defaultDateTimeFormat);

export const formatSecondsToNumberOfDays = (seconds: number): number => seconds / (60 * 60 * 24);

export const getThursdayToThursday = () => {
  const today = dayjs.utc();

  let startOfWeek;

  if (today.day() >= 4) {
    startOfWeek = today.day(4);
  } else {
    startOfWeek = today.subtract(1, 'week').day(4);
  }

  const endOfWeek = startOfWeek.add(7, 'days');

  return {
    startDate: startOfWeek.startOf('day').toISOString(),
    endDate: endOfWeek.startOf('day').toISOString(),
  };
};
