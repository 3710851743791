import { Box } from '@mui/material';
import { keyframes } from '@mui/material/styles';
import type { FC } from 'react';

import Isologo from './Isologo';

const spin = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(720deg);
    }
`;

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
        height: 48,
        width: 48,
        position: 'relative',
        '& img': {
          animation: `${spin} infinite 1.5s`,
        },
      }}
    >
      <Isologo />
    </Box>
  </Box>
);
